import React, { useEffect } from "react";
import sal from "sal.js";
import "sal.js/dist/sal.css";

const SalWrapper = ({ children, path, options }) => {
  useEffect(() => {
    sal(options);
  }, [path]);

  return children;
};

export const wrapPageElement = ({ element, props }) => (
  <SalWrapper
    options={{
      threshold: 0.1, // Percentage of an element's area that needs to be visible to launch animation
    }}
    path={props.path}
  >
    {element}
  </SalWrapper>
);
